<template>
    <div id="scanner">
        <!-- Choose shift -->
        <v-select
            v-model="shift"
            :items="shifts"
            class="mb-4 mt-4"
            label="Shift"
            dense
            outlined
            hide-details
        ></v-select>

        <v-progress-circular
            v-if="!buses"
            class="mb-6"
            indeterminate
            size="32"
            color="grey"
        ></v-progress-circular>

        <!-- Choose current bus -->
        <v-select
            v-else
            v-model="bus"
            :items="buses"
            class="mb-4"
            label="Busnummer"
            dense
            outlined
            hide-details
        ></v-select>

        <div v-if="bus">
            <!-- Camera loading -->
            <div v-show="!cameraInitialised" class="camera-loading">
                <v-progress-circular
                    class="center"
                    indeterminate
                    size="32"
                    color="grey"
                ></v-progress-circular>
            </div>

            <!-- Qrcode screen -->
            <qrcode-stream
                v-show="cameraInitialised"
                @decode="onDecode"
                @init="onInit"
                :track="paintOutline"
            ></qrcode-stream>

            <v-row align="center" class="mt-4 mb-2">
                <v-divider class="mr-4 ml-4"></v-divider>
                <label class="divider-text">Of</label>
                <v-divider class="mr-4 ml-4"></v-divider>
            </v-row>

            <!-- Manual workersnumber -->
            <v-row dense>
                <v-col cols="10">
                    <v-text-field
                        v-model="workerId"
                        label="Werknemersnummer"
                        dense
                        outlined
                        hide-details
                    />
                </v-col>

                <v-col cols="2">
                    <v-btn
                        class="pa-0"
                        color="primary"
                        height="40"
                        depressed
                        block
                        @click="$refs.searchWorkerDialog.open()"
                    >
                        <v-icon> mdi-account-search </v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-btn
                class="text-none mt-4"
                block
                depressed
                color="primary"
                :disabled="workerId == null"
                @click="validate(workerId)"
            >
                Valideren
            </v-btn>
        </div>

        <validation-dialog ref="validationDialog" @invalid="invalidWorker" />
        <invalid-worker-id-dialog ref="invalidWorkerIdDialog" />
        <search-worker-dialog
            ref="searchWorkerDialog"
            @worker="workerSelected"
        />
    </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";

import ValidationDialog from "@/components/scanner/ValidationDialog.vue";
import InvalidWorkerIdDialog from "@/components/scanner/InvalidWorkerIdDialog.vue";
import SearchWorkerDialog from "@/components/scanner/SearchWorkerDialog.vue";

export default {
    components: {
        QrcodeStream,
        ValidationDialog,
        InvalidWorkerIdDialog,
        SearchWorkerDialog,
    },
    data() {
        return {
            cameraInitialised: false,

            bus: null,
            buses: null,
            shift: null,
            shifts: [
                { text: "6h", value: "6" },
                { text: "6h - Retour", value: "6r" },
                /*
                { text: "8h", value: "8" },
                { text: "8h - Retour", value: "8r" },
                { text: "10h", value: "10" },
                { text: "10h - Retour", value: "10r" },
				*/
                { text: "14h", value: "14" },
                { text: "14h - Retour", value: "14r" },
                { text: "22h", value: "22" },
                { text: "22h - Retour", value: "22r" },
            ],
            workerId: null,
        };
    },
    mounted() {
        let counts = [6, 8, 10, 14, 22];
        let current = new Date().getHours();

        this.shift = counts
            .reduce(function (prev, curr) {
                return Math.abs(curr - current) < Math.abs(prev - current)
                    ? curr
                    : prev;
            })
            .toString();
    },
    methods: {
        async onInit(promise) {
            this.cameraInitialised = false;

            try {
                await promise;
            } catch (error) {
                if (error.name === "NotAllowedError") {
                    console.log("User denied camera access permisson");
                } else if (error.name === "NotFoundError") {
                    console.log("No suitable camera device installed");
                } else if (error.name === "NotSupportedError") {
                    console.log("Page is not served over HTTPS (or localhost)");
                } else if (error.name === "NotReadableError") {
                    console.log("Camera already in use");
                } else if (error.name === "StreamApiNotSupportedError") {
                    console.log("Browser seems to be lacking features");
                }
            } finally {
                this.cameraInitialised = true;
            }
        },
        onDecode(decodedString) {
            // workerId QR always starts with KTNPLANNER
            if (!decodedString.startsWith("KTNPLANNER")) {
                this.$refs.invalidWorkerIdDialog.open(decodedString);
            } else {
                this.validate(decodedString.replace("KTNPLANNER", ""));
            }
        },
        validate(id) {
            this.$refs.validationDialog.open(id);
            this.workerId = null;
        },
        parseBuses(buses) {
            let parsed = [];

            buses.forEach((bus) => {
                parsed.push({ text: "Bus " + bus.bus_number, value: bus.id });
            });

            return parsed;
        },
        invalidWorker(workerId) {
            this.$refs.invalidWorkerIdDialog.open(workerId);
        },
        paintOutline(detectedCodes, ctx) {
            for (const detectedCode of detectedCodes) {
                const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;

                ctx.strokeStyle = "red";

                ctx.beginPath();
                ctx.moveTo(firstPoint.x, firstPoint.y);
                for (const { x, y } of otherPoints) {
                    ctx.lineTo(x, y);
                }
                ctx.lineTo(firstPoint.x, firstPoint.y);
                ctx.closePath();
                ctx.stroke();
            }
        },
        workerSelected(workerId) {
            this.workerId = workerId;
        },
    },
    watch: {
        shift(value) {
            this.$store.commit("SET_CURRENT_SHIFT", value);

            this.bus = null;
            this.buses = null;

            this.$store.dispatch("getBuses").then((buses) => {
                this.buses = this.parseBuses(buses);
            });
        },
        bus(value) {
            this.$store.commit("SET_CURRENT_BUS", value);
        },
    },
};
</script>

<style>
.divider-text {
    color: rgba(0, 0, 0, 0.6);
}

.camera-loading {
    background-color: black;
    width: 320px;
    height: 240px;
}

.center {
    position: absolute;
    left: 46%;
    top: 53%;
}
</style>