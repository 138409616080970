<template>
    <v-dialog v-model="dialog" max-width="380">
        <v-card class="text-center">
            <v-btn class="close-button" icon x-small @click="dialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-card-text class="pt-4">
                <h4>{{ workerId }} is geen geldig werknemersnummer.</h4>
                <v-icon class="mt-2" x-large color="error">
                    mdi-alert-circle
                </v-icon>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "InvalidWorkerIdDialog",
    data() {
        return {
            dialog: false,
            workerId: null,
        };
    },
    methods: {
        open(workerId) {
            this.workerId = workerId;
            this.dialog = true;
        },
    },
};
</script>

<style>
</style>