import Vue from "vue";
import Vuex from "vuex";

import axios from "axios";

Vue.use(Vuex);

const dataModule = {
	state: {
		date: new Date().toISOString().split("T")[0],
		shift: null,
		retour: false,
		busId: null
	},
	mutations: {
		SET_CURRENT_SHIFT(state, shift) {
			state.shift = parseFloat(shift);
			state.retour = shift.includes('r');
		},
		SET_CURRENT_BUS(state, busId) {
			state.busId = busId;
		}
	},
	actions: {
		getBuses({ state }) {
			return new Promise((resolve, reject) => {
				axios.get("/api/scanner/buses?date=" + state.date + "&shift_start=" + state.shift)
					.then((resp) => {
						resolve(resp.data);
					})
					.catch(() => {
						reject();
					});
			});
		},
		validateWorker({ state }, workerId) {
			return new Promise((resolve, reject) => {
				axios.post("/api/scanner/validate/" + workerId, {
					shift_date: state.date,
					shift_start: state.shift,
					retour: state.retour,
					bus_id: state.busId
				})
					.then((resp) => {
						resolve(resp.data);
					})
					.catch(() => {
						reject();
					});
			});
		},
		addMemo(context, { loggingId, memo }) {
			return new Promise((resolve, reject) => {
				axios.post("/api/scanner/memo/" + loggingId, {
					memo: memo
				})
					.then(() => {
						resolve();
					})
					.catch(() => {
						reject();
					});
			});
		},
		searchWorker(context, search) {
			return new Promise((resolve, reject) => {
				search = search ? "?search=" + search : "";

				axios.get("/api/scanner/worker" + search).then((resp) => {
					resolve(resp.data);
				}).catch((error) => {
					reject(error);
				});
			});
		},
	},
	getters: {
		currentBusId(state) {
			return state.currentBusId;
		}
	},
};

export default dataModule;
