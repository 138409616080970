<template>
    <v-dialog v-model="dialog" max-width="400">
        <v-card class="text-center">
            <v-btn class="close-button" icon x-small @click="dialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-card-title></v-card-title>

            <v-card-text class="pb-2 pt-2">
                <v-text-field
                    v-model="memo"
                    label="Memo"
                    dense
                    outlined
                    hide-details
                />

                <p v-if="memoError" class="text-left red--text ma-0 mt-1">
                    <small>
                        Er ging iets mis bij het opslaan van de memo. Probeer
                        opnieuw.
                    </small>
                </p>
            </v-card-text>

            <v-card-actions>
                <v-btn text @click="dialog = false" :disabled="adding">
                    Annuleren
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn
                    text
                    @click="addMemo"
                    :loading="adding"
                    color="success"
                    :disabled="memo == null"
                >
                    Toevoegen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
            adding: false,
            memoError: false,

            loggingId: null,
            memo: null,
        };
    },
    methods: {
        open(id) {
            this.loggingId = id;
            this.dialog = true;
        },
        addMemo() {
            this.adding = true;
            this.memoError = false;

            this.$store
                .dispatch("addMemo", {
                    loggingId: this.loggingId,
                    memo: this.memo,
                })
                .catch(() => {
                    this.memoError = true;
                })
                .finally(() => {
                    this.adding = false;
                    this.dialog = false;
                });
        },
    },
};
</script>