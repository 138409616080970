import Vue from "vue";
import Vuex from "vuex";

import authModule from "./auth.js";
import dataModule from "./data.js";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		auth: authModule,
		data: dataModule,
	},
});
