<template>
    <v-app>
        <!-- Fullscreen Loader -->
        <v-overlay v-if="checkAuthenticated">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <!-- Application -->
        <v-main v-else>
            <v-container fill-height fluid>
                <v-row justify="center">
                    <v-col cols="12" align="center">
                        <v-card class="pa-10 pt-6 pb-6" width="400">
                            <!-- Logout -->
                            <v-btn
                                v-if="authenticated"
                                class="logout-button"
                                icon
                                small
                                color="grey lighten-1"
                                @click="signOut"
                            >
                                <v-icon>mdi-logout</v-icon>
                            </v-btn>

                            <router-view></router-view>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: "App",
    data() {
        return {
            initialCheck: false,
        };
    },
    computed: {
        checkAuthenticated() {
            return !this.$store.getters.authenticated && !this.initialCheck;
        },
        authenticated() {
            return this.$store.getters.authenticated;
        },
    },
    mounted() {
        this.$store.dispatch("me").finally(() => {
            this.initialCheck = true;
        });
    },
    methods: {
        signOut() {
            this.$store.dispatch("signOut");
        },
    },
    watch: {
        authenticated(authenticated) {
            if (authenticated) {
                this.$router.push({ name: "Scanner" });
            } else {
                this.$router.push({ name: "Login" });
            }
        },
    },
};
</script>

<style>
.v-application {
    background-color: rgb(241, 241, 241) !important;
}

.logout-button {
    position: absolute !important;
    top: 6px;
    right: 6px;
}
</style>
