<template>
    <v-dialog v-model="dialog" persistent max-width="400">
        <v-card class="text-center">
            <v-btn class="close-button" icon x-small @click="dialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-card-text class="pt-4">
                <div v-if="retrievingWorkerData">
                    <label>Gegevens UZK {{ workerId }} ophalen...</label><br />
                    <v-progress-circular
                        class="mt-3"
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                </div>

                <div v-else-if="data">
                    <h3 v-if="data.worker" id="name">{{ data.worker.name }}</h3>
                    <h4 v-if="data.worker" id="agency" class="mb-2">
                        {{ capitalize(data.worker.agency) }}
                    </h4>

                    <div v-if="data.status != 0 && data.shift" class="mb-2">
                        <label id="bus">
                            Busnummer:
                            {{
                                data.shift.bus_number
                                    ? data.shift.bus_number
                                    : "Geen bus"
                            }}
                        </label>
                        <br />

                        <label id="shift">
                            Shift: {{ data.shift.shift_time }}
                        </label>
                        <br />

                        <label id="operation">
                            Operation: {{ data.shift.operation }}
                        </label>
                        <br />

                        <label id="activiy">
                            Activity: {{ data.shift.activity }}
                        </label>
                        <br />
                    </div>

                    <div class="mt-2">
                        <h3>{{ workerStatusText }}</h3>

                        <v-icon
                            :color="workerStatusColor"
                            class="worker-status-icon mx-auto mb-4"
                            width="120"
                        >
                            {{ workerStatusIcon }}
                        </v-icon>
                    </div>

                    <v-btn
                        class="text-none"
                        depressed
                        color="primary"
                        small
                        @click="$refs.memoDialog.open(data.id)"
                    >
                        Memo toevoegen
                    </v-btn>

                    <!-- Memo -->
                    <memo-dialog ref="memoDialog" />
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import MemoDialog from "@/components/scanner/MemoDialog.vue";

export default {
    components: {
        MemoDialog,
    },
    data() {
        return {
            dialog: false,
            retrievingWorkerData: false,
            workerId: null,
            data: null,
        };
    },
    computed: {
        workerStatusColor() {
            if (this.data.status == 0) return "error";
            else if (this.data.status == 1) return "success";
            else return "warning";
        },
        workerStatusIcon() {
            if (this.data.status == 0) return "mdi-minus-circle-outline";
            else if (this.data.status == 1) return "mdi-check-circle-outline";
            else return "mdi-alert-circle-outline";
        },
        workerStatusText() {
            if (this.data.status == 0)
                return this.data.worker
                    ? "Niet in huidige shift"
                    : "Uitzendkracht niet gekend";
            else if (this.data.status == 1) return "Correct";
            else return "Verkeerde bus";
        },
    },
    methods: {
        open(workerId) {
            this.reset();

            this.workerId = workerId;

            // Retrieve worker information
            this.$store
                .dispatch("validateWorker", this.workerId)
                .then((resp) => {
                    this.data = resp;
                    this.retrievingWorkerData = false;
                })
                .catch(() => {
                    this.dialog = false;

                    // Worker not found
                    this.$emit("invalid", this.workerId);
                });

            this.dialog = true;
        },
        reset() {
            this.retrievingWorkerData = true;

            this.workerId = null;
            this.data = null;
        },
        capitalize(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
    },
};
</script>

<style scoped>
.v-icon.worker-status-icon {
    font-size: 150px;
}
</style>