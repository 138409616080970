<template>
    <form id="login" v-on:submit.prevent="signIn">
        <v-img
            :src="require(`@/assets/logo.jpg`)"
            class="mb-4 mx-auto"
            width="100"
        />

        <v-text-field
            v-model="username"
            label="Gebruikersnaam"
            type="text"
            required
        ></v-text-field>

        <v-text-field
            v-model="password"
            label="Wachtwoord"
            type="password"
            hide-details
            required
        ></v-text-field>

        <p v-if="loginError" class="text-left red--text ma-0 mt-1">
            <small>Het opgegeven wachtwoord is onjuist.</small>
        </p>

        <v-btn
            type="submit"
            class="white--text text-none mt-8"
            color="primary"
            large
            block
            depressed
            :loading="loggingIn"
        >
            Aanmelden
        </v-btn>
    </form>
</template>

<script>
export default {
    data() {
        return {
            username: null,
            password: null,

            loggingIn: false,
            loginError: false,
        };
    },
    methods: {
        signIn() {
            this.loggingIn = true;
            this.loginError = false;

            this.$store
                .dispatch("signIn", {
                    username: this.username,
                    password: this.password,
                })
                .then(() => {
                    this.$store.dispatch("me");
                })
                .catch(() => {
                    this.loginError = true;
                })
                .finally(() => {
                    this.loggingIn = false;
                });

            return false;
        },
    },
};
</script>

<style>
</style>