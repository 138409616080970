<template>
    <v-dialog v-model="dialog" max-width="400" persistent>
        <v-card class="text-center">
            <v-btn class="close-button" icon x-small @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-card-title>Uitzendkracht zoeken</v-card-title>

            <v-card-text class="pb-0">
                <form v-on:submit.prevent="searchWorker">
                    <v-text-field
                        v-model="search"
						class="mb-4"
                        type="text"
                        label="Zoekcriteria"
                        outlined
                        required
                        dense
                        hide-details
                    ></v-text-field>

                    <p v-if="notFoundError" class="text-left red--text mb-0">
                        Geen uitzendkracht gevonden.
                    </p>

                    <v-btn
                        class="text-none"
                        type="submit"
                        block
                        depressed
                        color="primary"
                        :loading="searching"
                    >
                        <v-icon class="mr-1" small>mdi-magnify</v-icon>
                        Zoeken
                    </v-btn>
                </form>

                <v-select
                    v-if="workers"
                    v-model="workerId"
                    :items="workers"
                    class="mt-4"
                    label="Uitzendkracht"
                    required
                    outlined
                    dense
                    hide-details
                />
            </v-card-text>

            <v-card-actions>
                <v-btn text @click="close" :disabled="searching">
                    Annuleren
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn
                    color="success"
                    text
                    @click="workerSelected"
                    :disabled="workerId == null"
                >
                    Bevestig
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
            search: null,
            searching: false,
            notFoundError: false,
            workers: null,
            workerId: null,
        };
    },
    methods: {
        open() {
            this.dialog = true;
        },
        close() {
            this.reset();

            this.dialog = false;
        },
        reset() {
            this.search = null;
            this.searching = false;
            this.notFoundError = false;
            this.workers = null;
            this.workerId = null;
        },
        searchWorker() {
            this.searching = true;
            this.notFoundError = false;
            this.workers = null;
            this.workerId = null;

            this.$store
                .dispatch("searchWorker", this.search)
                .then((workers) => {
                    if (workers.length == 0) {
                        this.notFoundError = true;
                    } else {
                        this.workers = this.parseWorkers(workers);
                    }
                })
                .finally(() => {
                    this.searching = false;
                });
        },
        workerSelected() {
            this.$emit("worker", this.workerId);

            this.close();
        },
        parseWorkers(workers) {
            let tmpWorkers = [];

            for (var i = 0; i < workers.length; ++i) {
                let worker = workers[i];

                tmpWorkers.push({
                    text:
                        worker.first_name +
                        " " +
                        worker.last_name +
                        " - " +
                        worker.id +
                        " - " +
                        this.capitalize(worker.agency),
                    value: worker.id,
                });
            }

            return tmpWorkers;
        },
        capitalize(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
    },
};
</script>