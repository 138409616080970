import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '@/views/Login.vue';
import Scanner from '@/views/Scanner.vue';

Vue.use(VueRouter)

const routes = [
	{
		path: '/login',
		name: 'Login',
		component: Login,
		meta: {
			requiresAuth: false,
		}
	},
	{
		path: '/scanner',
		name: 'Scanner',
		component: Scanner,
		meta: {
			requiresAuth: true,
		}
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
